//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseType from './BaseType.vue'
import { QuestionType } from '../../models/QuestionModel'
import TheMask from 'vue-the-mask/src/component'

export default {
  extends: BaseType,
  name: QuestionType.Text,
  components: {
    TheMask,
  },

  data() {
    return {
      inputType: 'text',
      canReceiveFocus: true
    }
  },

  methods: {
    validate() {
      if (this.question.mask && this.hasValue) {
        return this.validateMask()
      }

      return !this.question.required || this.hasValue
    },

    validateMask() {
      if (Array.isArray(this.question.mask)) {
        return this.question.mask.some(mask => mask.length === this.dataValue.length)
      }

      return this.dataValue.length === this.question.mask.length
    }
  }
}
