//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseType from './BaseType.vue'
import { QuestionType } from '../../models/QuestionModel'
import TextareaAutosize from 'vue-textarea-autosize/src/components/TextareaAutosize'

export default {
  extends: BaseType,
  name: QuestionType.LongText,
  components: {
    TextareaAutosize
  },
  data () {
    return {
      canReceiveFocus: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeListener)
  },
  methods: {
    onResizeListener() {
      this.$refs.input.resize()
    },

    unsetFocus($event) {
      if ($event || !this.isMobile) {
        this.focused = false
      }
    },

    onEnterDown($event) {
      if (!this.isMobile) {
        $event.preventDefault()
      }
    },

    onEnter() {
      if (!this.isMobile) {
        this._onEnter()
      }
    }
  }
}
